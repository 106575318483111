import React, { useState, useEffect } from "react";
import { Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import { TagPageView } from './helpers/GoogleTagHelper';
import {IsCombinedAppContainer, IsLatvianWeb, UsingApp} from "./helpers/AlternativeDesign";
import Login from "./pages/Login";
import CampaignLogin from "./pages/CampaignLogin";
import auth from "./auth/authenticate";
import { logout } from "./actions/authActions"
import { postDeviceRegistration } from "./actions/pushNotificationActions";

import Contracts from "./pages/Contracts";
import ContractFuel from "./pages/ContractFuel";
import ValidatorEt from "./lang/validatorEt";
import ValidatorLv from "./lang/validatorLv";
import ValidatorRu from "./lang/validatorRu";
import SimpleReactValidator from 'simple-react-validator';
import Consumption from "./pages/Consumption";
import Cards from "./pages/Cards";
import PaymentCards from "./pages/PaymentCards";
import Payments from "./pages/Payments";
import NotFound from "./pages/NotFound";
import Invoices from "./pages/Invoices";
import Overview from "./pages/Overview";
import Transactions from "./pages/Transactions";
import UserSettings from "./pages/UserSettings";
import CommunityProject from "./pages/CommunityProject";
import GiftCard from "./pages/GiftCard";
import FuelTaxiOrder from "./pages/FuelTaxiOrder";
import Stations from "./pages/Stations";
import Service from './pages/Service';
import ContractElectricity from './pages/ContractElectricity';
import ContractGas from './pages/ContractGas';
import AgentThankYou from './pages/AgentThankYou';
import ContractThankYou from './pages/ContractThankYou';
import ContractProductChangeThankYou from './pages/ContractProductChangeThankYou';
import CardOrderReferral from './pages/CardOrderReferral';
import AgentService from "./pages/AgentService";
import UnsubscribeEmails from "./pages/UnsubscribeEmails";
import TranslationSettings from "./pages/admin/TranslationSettings";
import Production from "./pages/Production";
import AuthorizedUsers from "./pages/AuthorizedUsers";
import InvoicePaymentResponse from "./pages/InvoicePaymentResponse";
import StockPriceAlerts from "./pages/admin/StockPriceAlerts";
import FuelContractDetails from "./pages/FuelContractDetails";
import Statistics from "./pages/Statistics";
import ContractEVCharging from "./pages/ContractEVCharging";
import BusinessOverview from "./pages/BusinessOverview";
import ContractProductChange from "./pages/ContractProductChange";
import BasketballCommunityProject from "./pages/BasketballCommunityProject";
import ElmoContractOffer from "./pages/ElmoContractOffer";
import ContractEVChargingAgent from "./pages/ContractEVChargingAgent";
import ContractRenewal from "./pages/ContractRenewal";
import SummerCampaign from "./pages/SummerCampaign";

import {
    GiftCardPath,
    StartPath,
    CardsPath,
    ConsumptionPath,
    ContractsPath,
    ContractFuelPath,
    SettingsPath,
    InvoicesPath,
    TransactionsPath,
    LoginPath,
    CommunityPath,
    FuelTaxiOrderPath,
    StationsPath,
    MainContactPath,
    ContactPath,
    ServicePath,
    ContractElectricityPath,
    ContractGasPath,
    ContractThankYouPath,
    ContractProductChangeThankYouPath,
    AgentContractThankYouPath,
    CardOrderReferralPath,
    AgentServicePath,
    AgentGasPath,
    AgentElectricityPath,
    AgentCommunityPath,
    UnsubscribeEmailsPath,
    getCardDetailsPath,
    NordpoolPath,
    LoginCallbackPath,
    EParakstsCallbackPath,
    TranslationSettingsPath,
    ProductionPath,
    AuthorizationsPath,
    InvoicePaymentResponsePath,
    getElectricityContractDetailsPath,
    getGasContractDetailsPath,
    StockPriceAlertsPath,
    getFuelContractDetailsPath,
    PaymentCardsPath,
    PaymentsPath,
    DigitalStampsClientPath,
    DigitalStampsClientFriendlyPath,
    DigitalStampsPath,
    getPaymentCardInvoiceDetailsPath,
    getElectricityInvoiceDetailsPath,
    StatisticsPath,
    ContractEVChargingPath,
    NotificationsPath,
    UserManagementSettingsPath,
    getElectricityContractChangePath,
    MarketingNotificationsPath,
    DebugPath,
    HelpAndContactsPath,
    AudiCampaignPath,
    BasketballCommunityPath,
    getElectricityContractOfferPath,
    getGasContractOfferPath,
    PersonalizeBonuscardPath,
    getElectricityContractProductPreSelectPath,
    getCardContractOfferPath,
    getCylinderGasContractOfferPath,
    FamilyGroupPath,
    getEvChargingContractOfferPath,
    HintHistoryPath,
    getElectricityContractRenewalPath,
    getEvChargingContractOfferAgentPath,
    SmsLoginPagePath,
    ErrorPagePath,
    PartnerApiPath,
    ContractSignedPath,
    ContractRenewalThankYouPath,
    ReadingsGasPath,
    SummerCampaignPath,
    getGasContractChangePath,
    RolesPath,
    getGasContractProductPreSelectPath
} from "./const/routes";
import AppRoute from "./components/common/AppRoute";
import Contact from "./pages/Contact";
import MainContact from "./pages/MainContact";
import CardDetails from "./pages/CardDetails";
import Nordpool from "./pages/Nordpool";
import LoginCallback from "./pages/LoginCallback";
import InvoiceElectricity from "./pages/InvoiceElectricity";
import InvoicePaymentCard from "./pages/InvoicePaymentCard";
import Notifications from "./pages/Notifications";
import UserManagement from "./pages/admin/UserManagement";
import MarketingNotifications from "./pages/admin/MarketingNotifications";
import DigitalStamps from "./pages/DigitalStamps";
import DigitalStampsClient from "./pages/DigitalStampsClient";
import Debug from "./pages/admin/Debug";
import HelpAndContacts from "./pages/HelpAndContacts";
import { getDeviceRegistrationInfo } from "./helpers/pushNotificationHelper";
import { sendLoginStatusToAppContainerWithDelay } from "./helpers/appContainerHelper";
import { useRoute, withAppContainerMessageListener } from "./helpers/hooks";
import ElmoContractDetails from "./pages/ElmoContractDetails";
import FamilyGroup from "./pages/FamilyGroup";
import HintsHistory from "./pages/admin/HintsHistory";
import SmsLogin from "./pages/SmsLogin";
import ErrorPage from "./pages/ErrorPage";
import PartnerApi from "./pages/PartnerApi";
import EParakstsCallback from "./pages/EParakstsCallback";
import ContractSigned from "./pages/ContractSigned";
import GasoMeterSubmission from "./pages/GasoMeterSubmission";
import {Electricity, Gas} from "./const/contractType";
import {useFeature} from 'flagged';
import {GasoMeterSubmit, LotteryCampaign2024} from "./const/featureFlags";

const Layout = () => {
    const [isCompany, setIsCompany] = useState(auth.isCompany())
    const [isAdmin, setIsAdmin] = useState(auth.isAdmin());

    const role = useSelector(state => state.auth.role);
    const {
        fetched: posted,
        fetching: posting,
        error: postError
    } = useSelector(state => state.postDeviceRegistration);
    const isMobileOrTabletView = useSelector(state => state.ui.isMobileOrTabletView)

    const Route = AppRoute;
    const history = useHistory();
    const dispatch = useDispatch();
    const redirectIfMatch = useRoute();
    const gasoMeterSubmit = useFeature(GasoMeterSubmit);
    const lotteryCampaign = useFeature(LotteryCampaign2024);

    useEffect(() => {
        setIsCompany(auth.isCompany());
        setIsAdmin(auth.isAdmin());
    }, [role]);

    useEffect(() => {
        const listener = history.listen((location) => {
            TagPageView(location.pathname);
            redirectIfMatch(location);
        });

        redirectIfMatch(history.location);

        return listener;
    }, []);

    if (auth.getTokenJwt() && !auth.isLoggedIn()) {
        dispatch(logout());
    }

    SimpleReactValidator.addLocale('et', ValidatorEt);
    SimpleReactValidator.addLocale('ru', ValidatorRu);
    SimpleReactValidator.addLocale('lv', ValidatorLv);

    TagPageView(history.location.pathname);

    if (auth.isLoggedIn() && UsingApp() && IsCombinedAppContainer()) {
        sendLoginStatusToAppContainerWithDelay(true);
    }

    // Register app for push notifications
    useEffect(() => {
        if (posting || posted || postError) {
            return;
        }

        if (auth.isLoggedIn() && UsingApp()) {
            const payload = getDeviceRegistrationInfo();
            if (payload) {
                dispatch(postDeviceRegistration(payload));
            }
        }
    }, [posting, posted, postError, dispatch])

    return (
        <Switch>
            <Route exact path={StartPath} layout="DefaultLayout"
                layoutParams={{
                    title: <span className="desktop-hidden"><FormattedMessage id="Menu.Workspace" /></span>,
                    showBanner: true
                }}
                component={isCompany ? BusinessOverview : Overview} />

            <Route exact path={RolesPath} layout="DefaultLayout"
                layoutParams={{
                    title: <span className="desktop-hidden"><FormattedMessage id="Menu.Workspace" /></span>,
                    showBanner: true
                }}
                component={isCompany ? BusinessOverview : Overview} />

            <Route exact path={getCardDetailsPath()} layout="DefaultLayout"
                layoutParams={{
                    title: <FormattedMessage id="Menu.Cards" />
                }}
                component={CardDetails} />

            <Route exact path={AudiCampaignPath} layout="DefaultLayout"
                layoutParams={{
                    title: <FormattedMessage id="Menu.Cards" />
                }}
                component={Cards} />

            <Route exact path={PersonalizeBonuscardPath} layout="DefaultLayout"
                layoutParams={{
                    title: <FormattedMessage id="Menu.Cards" />
                }}
                component={Cards} />

            <Route exact path={AuthorizationsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Authorizations" /> }}
                component={AuthorizedUsers} />

            {isAdmin &&
                <Route exact path={TranslationSettingsPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.TranslationSettings" /> }}
                    component={TranslationSettings} />}

            {isAdmin &&
                <Route exact path={StockPriceAlertsPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.StockPriceAlerts" /> }}
                    component={StockPriceAlerts} />}

            <Route exact path={ConsumptionPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Consumption" /> }}
                component={Consumption} />

            {!IsLatvianWeb() && <Route exact path={ProductionPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Production" /> }}
                component={Production} />}

            <Route exact path={ContractsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Contracts" /> }}
                component={Contracts} />

            <Route exact path={ContractFuelPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Contracts" /> }}
                component={ContractFuel} />

            <Route path={getCardContractOfferPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Contracts" /> }}
                component={ContractFuel} />

            <Route path={getCylinderGasContractOfferPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Contracts" /> }}
                component={ContractFuel} />

            <Route exact path={getElectricityContractDetailsPath()} layout="DefaultLayout" component={ElmoContractDetails} />
            <Route exact path={getGasContractDetailsPath()} layout="DefaultLayout" component={ElmoContractDetails} />

            <Route exact path={getFuelContractDetailsPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Leping" /> }}
                component={FuelContractDetails} />

            <Route exact path={getElectricityContractChangePath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Contract.PackageChange" /> }}>
                <ContractProductChange contractType={Electricity}/>
            </Route>

            <Route exact path={getGasContractChangePath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Contract.PackageChange" /> }}>
                <ContractProductChange contractType={Gas}/>
            </Route>

            <Route exact path={getElectricityContractRenewalPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.ContractRenewal" /> }}
                component={ContractRenewal} />

            <Route exact path={CardsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Cards" /> }}
                component={Cards} />

            <Route exact path={PaymentCardsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.PaymentCards" /> }}
                component={PaymentCards} />

            <Route exact path={InvoicesPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Invoices" /> }}
                component={Invoices} />

            <Route exact path={BasketballCommunityPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.BasketballCommunity" /> }}
                component={BasketballCommunityProject} />

            <Route exact path={NordpoolPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Nordpool" defaultMessage="Nordpool" /> }}
                component={Nordpool} />

            <Route exact path={getPaymentCardInvoiceDetailsPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Invoice.PaymentCard.PageTitle" /> }}
                component={InvoicePaymentCard} />

            <Route exact path={getElectricityInvoiceDetailsPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Invoice.PageTitle" /> }}
                component={InvoiceElectricity} />

            <Route exact path={InvoicePaymentResponsePath} layout="DefaultLayout"
                component={InvoicePaymentResponse} />

            <Route exact path={TransactionsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Transactions" /> }}
                component={Transactions} />

            <Route exact path={CommunityPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Community" /> }}
                component={CommunityProject} />

            <Route exact path={NordpoolPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Nordpool" defaultMessage="Nordpool" /> }}
                component={Nordpool} />

            <Route exact path={LoginPath} layout="LoginLayout" isPrivate={false} component={Login} />
            <Route exact path={LoginCallbackPath} layout="LoginLayout" isPrivate={false} component={LoginCallback} />
            <Route exact path={EParakstsCallbackPath} layout="DefaultLayout" isPrivate={false} component={EParakstsCallback} />
            <Route exact path="/campaign-login/:code/:type?/:item?" layout="LoginLayout" isPrivate={false} component={CampaignLogin} />

            <Route exact path={ContractThankYouPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.ThankYou" /> }}
                component={ContractThankYou} />
            <Route exact path={ContractSignedPath} layout="DefaultLayout" component={ContractSigned} />
            <Route exact path={ContractProductChangeThankYouPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.ThankYou" /> }}
                component={ContractProductChangeThankYou} />
            <Route exact path={ContractRenewalThankYouPath} layout="DefaultLayout"
                   layoutParams={{title: <FormattedMessage id="Menu.ThankYou"/>}}>
                <ContractProductChangeThankYou isRenewal={true}/>
            </Route>

            <Route exact path={getElectricityContractProductPreSelectPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Contract.Electricity.Title" /> }}
                component={ContractElectricity} />

            <Route exact path={getGasContractProductPreSelectPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Contract.Gas.Title" /> }}
                component={ContractGas} />

            <Route exact path={ContractElectricityPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Contract.Electricity.Title" /> }}
                component={ContractElectricity} />

            {isCompany &&
                <Route path={getElectricityContractOfferPath()} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Contract.ElectricityOffer.Title" /> }}
                    component={ElmoContractOffer} />
            }

            {isCompany &&
                <Route path={getGasContractOfferPath()} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Contract.GasOffer.Title" /> }}
                    component={ElmoContractOffer} />
            }

            <Route exact path={ContractGasPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Contract.Gas.Title" /> }}
                component={ContractGas} />

            <Route exact path={SettingsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Settings" /> }}
                component={UserSettings} />

            <Route exact path={FuelTaxiOrderPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="FuelTaxiOrderForm.Title" /> }}
                component={FuelTaxiOrder} />

            <Route path={GiftCardPath} layout="EmptyLayout" component={GiftCard} isPrivate={false} />

            <Route exact path={ServicePath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Service" /> }}
                component={Service} />

            <Route exact path={StationsPath} layout="DefaultLayout" component={Stations}
                layoutParams={{ title: <FormattedMessage id="Menu.Stations" /> }} />

            <Route exact path={AgentServicePath} layout="AgentLayout"
                component={AgentService} />

            <Route exact path={AgentGasPath} layout="AgentLayout"
                component={ContractGas} />

            <Route exact path={AgentElectricityPath} layout="AgentLayout"
                component={ContractElectricity} />

            <Route exact path={AgentCommunityPath} layout="AgentLayout"
                component={CommunityProject} />

            <Route exact path={AgentContractThankYouPath} layout="AgentLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.ThankYou" /> }}
                component={AgentThankYou} />

            <Route exact path={ContactPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Settings.ContactDetails" /> }}
                component={Contact} />

            <Route exact path={MainContactPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Settings.ContactDetails" /> }}
                component={MainContact} />

            <Route path={NotificationsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Notifications" /> }}
                component={Notifications} />

            <Route exact path={DigitalStampsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.DigitalStamps" /> }}
                component={DigitalStamps} />

            <Route path={CardOrderReferralPath} layout="EmptyLayout" component={CardOrderReferral} isPrivate={false} />

            <Route path={UnsubscribeEmailsPath + "/:code"} layout="EmptyLayout" component={UnsubscribeEmails} isPrivate={false} />

            <Route path={[DigitalStampsClientPath, DigitalStampsClientFriendlyPath]} layout="EmptyLayout"
                component={DigitalStampsClient} isPrivate={false} />

            {isCompany && <Route exact path={PaymentsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.Payments" /> }}
                component={Payments} />
            }

            {isCompany &&
                <Route exact path={StatisticsPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.Statistics" /> }}
                    component={Statistics} />
            }

            <Route exact path={ContractEVChargingPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Contract.EVCharging.Title" /> }}
                component={ContractEVCharging} />

            <Route exact path={getEvChargingContractOfferPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedHTMLMessage id="Contract.EvCharging.Offer.Title2" /> }}
                component={ContractEVCharging} />

            <Route exact path={getEvChargingContractOfferAgentPath()} layout="DefaultLayout"
                layoutParams={{ title: <FormattedHTMLMessage id="Contract.EvCharging.Offer.Title2" /> }}
                component={ContractEVChargingAgent} />

            {isAdmin &&
                <Route exact path={UserManagementSettingsPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.UserManagement" /> }}
                    component={UserManagement} />
            }

            {isAdmin &&
                <Route exact path={MarketingNotificationsPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.MarketingNotifications" /> }}
                    component={MarketingNotifications} />
            }

            {isAdmin &&
                <Route exact path={HintHistoryPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.HintHistory" /> }}
                    component={HintsHistory} />
            }

            {isAdmin &&
                <Route exact path={DebugPath} layout="DefaultLayout"
                    layoutParams={{ title: "Debug" }}
                    component={Debug} />
            }

            <Route exact path={HelpAndContactsPath} layout="DefaultLayout"
                layoutParams={{ title: <FormattedMessage id="Menu.HelpAndContacts" /> }}
                component={HelpAndContacts} />

            {!isCompany &&
                <Route exact path={FamilyGroupPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.FamilyGroup" /> }}
                    component={FamilyGroup} />
            }

            {isCompany &&
                <Route exact path={PartnerApiPath} layout="DefaultLayout"
                    layoutParams={{ title: <FormattedMessage id="Menu.PartnerApi" /> }}
                    component={PartnerApi} />
            }

            {IsLatvianWeb() && gasoMeterSubmit &&
                <Route exact path={ReadingsGasPath} layout="DefaultLayout"
                       layoutParams={{ title: <FormattedMessage id="Menu.GasSubmissions" /> }}
                       component={GasoMeterSubmission} />
            }

            {!IsLatvianWeb() && !isCompany && lotteryCampaign &&
                <Route exact path={SummerCampaignPath} layout="DefaultLayout"
                    layoutParams={{ title: isMobileOrTabletView
                        ? null
                        : <FormattedMessage id="Menu.SummerCampaign" /> }}
                    component={SummerCampaign} />
            }

            <Route exact path={SmsLoginPagePath} layout="EmptyLayout" component={SmsLogin} />

            <Route exact path={ErrorPagePath} layout="DefaultLayout" component={ErrorPage} />

            <Route path="*" layout="DefaultLayout" component={NotFound} />
        </Switch>
    );
}

export default withAppContainerMessageListener(Layout);
